import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/layout/Layout.vue"),
  },
  {
    path: "/callback-spotify",
    name: "Callback",
    component: () => import("@/discover/components/CallbackSpotify.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/error/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/error/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// router.beforeEach((to, from, next) => {
//   next();
// });

export default router;
