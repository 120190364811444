import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import BodyModule from "../store/modules/BodyModule";
import BreadcrumbsModule from "../store/modules/BreadcrumbsModule";
import ConfigModule from "../store/modules/ConfigModule";
import EventsModule from "../store/modules/Events.store.js";
import DiscoverModule from "../store/modules/Discover.store.js";
import SpotifyAccessTokenHelperModule from "../store/modules/SpotifyAccessTokenHelper.store.js";
import TrackPlaybackModule from "../store/modules/TrackPlayback.store.js";
import BackgroundsModule from "../store/modules/BackgroundsModule.store.js";

import createPersistedState from "vuex-persistedstate";

config.rawError = true;
const store = createStore({
  modules: {
    BodyModule,
    BreadcrumbsModule,
    BackgroundsModule,
    ConfigModule,
    DiscoverModule,
    EventsModule,
    SpotifyAccessTokenHelperModule,
    TrackPlaybackModule,
  },
  plugins: [
    createPersistedState({
      paths: ["SpotifyAccessTokenHelperModule", "DiscoverModule"],
      storage: window.sessionStorage,
    }),
  ],
});

export default store;
