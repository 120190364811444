import { ElementAnimateUtil } from "@/assets/ts/_utils";

const state = {
  currentlySelectedMenu: "New Releases",
  appLoading: false,
  isBannerDisplayed: true,
};

const getters = {
  getCurrentlySelectedMenu(state) {
    return state.currentlySelectedMenu;
  },
  getAppLoading(state) {
    return state.appLoading;
  },
  getBannerState(state) {
    return state.isBannerDisplayed;
  },
};

const actions = {
  async changeSelectedMenu({ commit }, selectedMenu) {
    commit("CHANGE_SELECTED_MENU", selectedMenu);
  },

  async resetStore({ commit }) {
    commit("RESET_STORE");
  },
  setAppLoading({ commit }, payload) {
    commit("SET_APP_LOADING", payload);
  },
  // eslint-disable-next-line no-empty-pattern
  scrollToElement({}, id) {
    const element = document.getElementById(id);
    if (element) {
      setTimeout(() => {
        ElementAnimateUtil.scrollTo(element, 50, 800);
      }, 500);
    }
  },
  hideBanner({ commit }) {
    commit("HIDE_BANNER");
  },
};

const mutations = {
  CHANGE_SELECTED_MENU(state, selectedMenu) {
    state.currentlySelectedMenu = selectedMenu;
  },
  SET_APP_LOADING(state, payload) {
    state.appLoading = payload;
  },
  RESET_STORE(state) {
    state.currentlySelectedMenu = "My Playlists";
    state.appLoading = false;
  },
  HIDE_BANNER(state) {
    state.isBannerDisplayed = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
