const state = {
  selectedClassIndex: 1,
};

const getters = {
  getSelectedClassIndex(state) {
    return state.selectedClassIndex;
  },
};

const actions = {
  setSelectedClassIndex({ commit }, selectedClassIndex) {
    commit("SET_SELECTED_CLASS_INDEX", selectedClassIndex);
  },
};

const mutations = {
  SET_SELECTED_CLASS_INDEX(state, selectedClassIndex) {
    state.selectedClassIndex = selectedClassIndex;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
