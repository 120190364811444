// State Object
const state = {
  artistSearchQuery: "",
};

const getters = {
  getArtistSearchQuery(state) {
    return state.artistSearchQuery;
  },
};

const actions = {
  async searchArtist({ commit }, searchQuery) {
    // await router.push("/artists");
    commit("UPDATE_ARTIST_SEARCH_QUERY", searchQuery);
  },
  clearSearchQuery({ commit }) {
    commit("CLEAR_SEARCH_QUERY");
  },
  resetStore({ commit }) {
    commit("RESET_STORE");
  },
};

const mutations = {
  UPDATE_ARTIST_SEARCH_QUERY(state, searchQuery) {
    state.artistSearchQuery = searchQuery;
  },
  CLEAR_SEARCH_QUERY(state) {
    state.artistSearchQuery = "";
  },
  RESET_STORE(state) {
    state.artistSearchQuery = "";
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
